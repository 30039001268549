@charset "utf-8";

/*===========================================
Noto Sans ---- WebFont
参考URL：http://qiita.com/nowri/items/1c69b9b25f2958bd9f97
===========================================*/

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 100;
	src: url('../font/NotoSansCJKjp-Thin/NotoSansCJKjp-Thin.eot');
	src: url('../font/NotoSansCJKjp-Thin/NotoSansCJKjp-Thin.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Thin/NotoSansCJKjp-Thin.woff') format('woff'),
	url('../font/NotoSansCJKjp-Thin/NotoSansCJKjp-Thin.ttf')  format('truetype');
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 200;
	src: url('../font/NotoSansCJKjp-Light/NotoSansCJKjp-Light.eot');
	src: url('../font/NotoSansCJKjp-Light/NotoSansCJKjp-Light.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Light/NotoSansCJKjp-Light.woff') format('woff'),
	url('../font/NotoSansCJKjp-Light/NotoSansCJKjp-Light.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 300;
	src: url('../font/NotoSansCJKjp-DemiLight/NotoSansCJKjp-DemiLight.eot');
	src: url('../font/NotoSansCJKjp-DemiLight/NotoSansCJKjp-DemiLight.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-DemiLight/NotoSansCJKjp-DemiLight.woff') format('woff'),
	url('../font/NotoSansCJKjp-DemiLight/NotoSansCJKjp-DemiLight.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 400;
	src: url('../font/NotoSansCJKjp-Regular/NotoSansCJKjp-Regular.eot');
	src: url('../font/NotoSansCJKjp-Regular/NotoSansCJKjp-Regular.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Regular/NotoSansCJKjp-Regular.woff') format('woff'),
	url('../font/NotoSansCJKjp-Regular/NotoSansCJKjp-Regular.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 500;
	src: url('../font/NotoSansCJKjp-Medium.eot');
	src: url('../font/NotoSansCJKjp-Medium.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Medium.woff') format('woff'),
	url('../font/NotoSansCJKjp-Medium.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 700;
	src: url('../font/NotoSansCJKjp-Bold/NotoSansCJKjp-Bold.eot');
	src: url('../font/NotoSansCJKjp-Bold/NotoSansCJKjp-Bold.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Bold/NotoSansCJKjp-Bold.woff') format('woff'),
	url('../font/NotoSansCJKjp-Bold/NotoSansCJKjp-Bold.ttf')  format('truetype');
}
@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 900;
	src: url('../font/NotoSansCJKjp-Black/NotoSansCJKjp-Black.eot');
	src: url('../font/NotoSansCJKjp-Black/NotoSansCJKjp-Black.eot?#iefix') format('embedded-opentype'),
	url('../font/NotoSansCJKjp-Black/NotoSansCJKjp-Black.woff') format('woff'),
	url('../font/NotoSansCJKjp-Black/NotoSansCJKjp-Black.ttf')  format('truetype');
}


.notoSans *{
font-family: 'Noto Sans Japanese';
}
* .notoSans{
font-family: 'Noto Sans Japanese';
}

.notoSans-ar *{
font-family: arial,'Noto Sans Japanese';
}
* .notoSans-ar{
font-family: arial,'Noto Sans Japanese';
}


/******************************
GenShinGothic-P Font
*******************************/

@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 100;
    src: url('../font/GenShinGothic-P-ExtraLight/GenShinGothic-P-ExtraLight.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-ExtraLight/GenShinGothic-P-ExtraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-ExtraLight/GenShinGothic-P-ExtraLight.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-ExtraLight/GenShinGothic-P-ExtraLight.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 200;
    src: url('../font/GenShinGothic-P-Light/GenShinGothic-P-Light.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-Light/GenShinGothic-P-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-Light/GenShinGothic-P-Light.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-Light/GenShinGothic-P-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 300;
    src: url('../font/GenShinGothic-P-Regular/GenShinGothic-P-Regular.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-Regular/GenShinGothic-P-Regular?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-Regular/GenShinGothic-P-Regular.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-Regular/GenShinGothic-P-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}



@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 400;
    src: url('../font/GenShinGothic-P-Medium/GenShinGothic-P-Medium.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-Medium/GenShinGothic-P-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-Medium/GenShinGothic-P-Medium.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-Medium/GenShinGothic-P-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 600;
    src: url('../font/GenShinGothic-P-Bold/GenShinGothic-P-Bold.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-Bold/GenShinGothic-P-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-Bold/GenShinGothic-P-Bold.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-Bold/GenShinGothic-P-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'GenShinGothic-P';
    font-style: normal;
    font-weight: 700;
    src: url('../font/GenShinGothic-P-Heavy/GenShinGothic-P-Heavy.eot'); /* IE9 Compat Modes */
    src: url('../font/GenShinGothic-P-Heavy/GenShinGothic-P-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../font/GenShinGothic-P-Heavy/GenShinGothic-P-Heavy.woff') format('woff'), /* Modern Browsers */
    url('../font/GenShinGothic-P-Heavy/GenShinGothic-P-Heavy.ttf')  format('truetype'); /* Safari, Android, iOS */
}




@media (min-width: 540px) {

.GenShinGothic-P *{
font-family: 'GenShinGothic-P';
}
* .GenShinGothic-P{
font-family: 'GenShinGothic-P';
}

.GenShinGothic-P-ar *{
font-family:arial, 'GenShinGothic-P';
}
* .GenShinGothic-P-ar{
font-family: arial,'GenShinGothic-P';
}
}


